require("core-js/modules/es.array.reduce.js");
require("core-js/modules/es.array.reduce.js");
var plugins = require("./api-runner-browser-plugins");
var _require$publicLoader = require("./loader").publicLoader,
  getResourcesForPathname = _require$publicLoader.getResourcesForPathname,
  getResourcesForPathnameSync = _require$publicLoader.getResourcesForPathnameSync,
  getResourceURLsForPathname = _require$publicLoader.getResourceURLsForPathname,
  loadPage = _require$publicLoader.loadPage,
  loadPageSync = _require$publicLoader.loadPageSync;
exports.apiRunner = function (api, args, defaultReturn, argTransform) {
  if (args === void 0) {
    args = {};
  }
  // Hooks for gatsby-cypress's API handler
  if (process.env.CYPRESS_SUPPORT) {
    if (window.___apiHandler) {
      window.___apiHandler(api);
    } else if (window.___resolvedAPIs) {
      window.___resolvedAPIs.push(api);
    } else {
      window.___resolvedAPIs = [api];
    }
  }
  var results = plugins.map(function (plugin) {
    if (!plugin.plugin[api]) {
      return undefined;
    }

    // Deprecated April 2019. Use `loadPageSync` instead
    args.getResourcesForPathnameSync = getResourcesForPathnameSync;
    // Deprecated April 2019. Use `loadPage` instead
    args.getResourcesForPathname = getResourcesForPathname;
    args.getResourceURLsForPathname = getResourceURLsForPathname;
    args.loadPage = loadPage;
    args.loadPageSync = loadPageSync;
    var result = plugin.plugin[api](args, plugin.options);
    if (result && argTransform) {
      args = argTransform({
        args: args,
        result: result,
        plugin: plugin
      });
    }
    return result;
  });

  // Filter out undefined results.
  results = results.filter(function (result) {
    return typeof result !== "undefined";
  });
  if (results.length > 0) {
    return results;
  } else if (defaultReturn) {
    return [defaultReturn];
  } else {
    return [];
  }
};
exports.apiRunnerAsync = function (api, args, defaultReturn) {
  return plugins.reduce(function (previous, next) {
    return next.plugin[api] ? previous.then(function () {
      return next.plugin[api](args, next.options);
    }) : previous;
  }, Promise.resolve());
};