'use strict';

var has = require('has');
var assertRecord = require('../helpers/assertRecord');
var Type = require('./Type');

// https://ecma-international.org/ecma-262/6.0/#sec-isaccessordescriptor

module.exports = function IsAccessorDescriptor(Desc) {
  if (typeof Desc === 'undefined') {
    return false;
  }
  assertRecord(Type, 'Property Descriptor', 'Desc', Desc);
  if (!has(Desc, '[[Get]]') && !has(Desc, '[[Set]]')) {
    return false;
  }
  return true;
};