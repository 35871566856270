'use strict';

var toPrimitive = require('es-to-primitive/es2015');

// https://ecma-international.org/ecma-262/6.0/#sec-toprimitive

module.exports = function ToPrimitive(input) {
  if (arguments.length > 1) {
    return toPrimitive(input, arguments[1]);
  }
  return toPrimitive(input);
};