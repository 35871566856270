import React from 'react'
import { useEffect } from 'react'
import { useDeck } from 'gatsby-theme-mdx-deck'

export const StepsCustom = props => {
    const context = useDeck();
    useEffect(() => {
        if (typeof context.register !== 'function') return;
        let count = 0;
        const children = React.Children.toArray(props.children);
        for(let i = 0; i < children.length; i++){
            const child = children[i];
            if(child.props && child.props.children){
                const sub_children = React.Children.toArray(child.props.children)
                if(sub_children.length === 0){
                    count++;
                }else {
                    for(let j = 0; j < sub_children.length; j++){
                        if(typeof(sub_children[j]) !== "object"){
                            count++;
                            break;
                        }
                    }
                }
            }
        }
        context.register(context.index, 'steps', count);
      }, [props.children]);
    let n = 0;
    const wrappedChildren = React.Children.map(
        props.children,
        child => {
            if (child.props && child.props.children) {
                const children = React.Children.toArray(child.props.children);
                if(children.length === 0){
                    return React.cloneElement(
                        {
                            ...child,
                        },
                        {
                            ...child.props,
                            style: {
                                ...child.props.style,
                                visibility: n++ < context.step || context.preview ? 'visible' : 'hidden'
                            }
                        }
                    );
                } else {
                    for(let i = 0; i < children.length; i++){
                        if(typeof(children[i]) !== "object"){
                            return React.cloneElement(
                                {
                                    ...child,
                                },
                                {
                                    ...child.props,
                                    style: {
                                        ...child.props.style,
                                        visibility: n++ < context.step || context.preview ? 'visible' : 'hidden'
                                    }
                                }
                            );
                        }
                    }
                }
                return React.cloneElement(
                    {
                        ...child,
                    },
                    {
                        ...child.props,
                        // Wrap grandchildren too
                        children: (
                            <StepsCustom>
                                {children}
                            </StepsCustom>
                        )
                    }
                )
                
            }
            return child
        }
    )
    return (
        <>
            {wrappedChildren}
        </>
    )
}

export default StepsCustom
