import { CodeSurfer, CodeSurferColumns, Step } from "code-surfer";
import { vsDark } from "@code-surfer/themes";
import { hack } from "@mdx-deck/themes";
import { StepsCustom } from "../../../../../../StepsCustom.js";
import { VideoCustom } from "../../../../../../VideoCustom.js";
import { CenterCustom } from "../../../../../../CenterCustom.js";
import * as React from 'react';
export default {
  CodeSurfer: CodeSurfer,
  CodeSurferColumns: CodeSurferColumns,
  Step: Step,
  vsDark: vsDark,
  hack: hack,
  StepsCustom: StepsCustom,
  VideoCustom: VideoCustom,
  CenterCustom: CenterCustom,
  React: React
};