import React from 'react'

export const CenterCustom = props => {
    const style = {
        display: "flex",
        justifyContent: "center"
    };
    return (<div style={style}>{props.children}</div>);
};

export default CenterCustom