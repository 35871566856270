import React from 'react'
import { useEffect, useRef } from 'react'
import { useSteps, useDeck } from 'gatsby-theme-mdx-deck'

export const VideoCustom = props => {
    const widthPercent = props.widthPercent;
    const videoPath = props.src;
    const context = useDeck();
    const step = useSteps(1);
    const videoElement = useRef(null);
    useEffect(() => {
        if(!context.preview && context.mode === "NORMAL" && step > 0 && videoElement.current){
            videoElement.current.currentTime = 0;
            videoElement.current.play();
        }
    },[step,videoElement]);

    return (<video preload={"auto"} ref={videoElement} playsInline style={{marginLeft:((100-widthPercent)/2)+"%",marginRight:((100-widthPercent)/2)+"%",width:widthPercent+"%",height:"auto"}}>
        <source src={videoPath} type="video/mp4"></source>
    </video>);
};

export default VideoCustom