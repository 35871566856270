'use strict';

var GetIntrinsic = require('get-intrinsic');
var $preventExtensions = GetIntrinsic('%Object.preventExtensions%', true);
var $isExtensible = GetIntrinsic('%Object.isExtensible%', true);
var isPrimitive = require('../helpers/isPrimitive');

// https://ecma-international.org/ecma-262/6.0/#sec-isextensible-o

module.exports = $preventExtensions ? function IsExtensible(obj) {
  return !isPrimitive(obj) && $isExtensible(obj);
} : function IsExtensible(obj) {
  return !isPrimitive(obj);
};